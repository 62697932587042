import React from "react"

import WesternNav from "./westernnav"
import Footer from "./footer"
import "../styles/main.scss"


const WesternLayout = (props) => {

  return (
    <div className="site__western">
      <WesternNav />
        <main>{props.children}</main>
      <Footer />
    </div>
  )
}

export default WesternLayout
