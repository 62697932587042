import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import scrollTo from 'gatsby-plugin-smoothscroll';

const CustomFlexCard = (props) => {
  return (
    <div className="card">
      <div className={props.rowType}>
        <div className="col">
          <div className="card__image">
            <LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
          </div>
        </div>
        <div className="col">
          <div className="card__content">
            <h3>{props.header}</h3>
            <h6>{props.subheader}</h6>
            <p>{props.text}</p>
            {props.children}
            {props.subtext && <p className="subtext">{props.subtext}</p>}
            {props.url ? <button className={`btn ${props.btnStyle} ${props.btnCustom}`} onClick={() => scrollTo(props.url)}>
                {props.btnTxt}
              </button> : ''}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomFlexCard;